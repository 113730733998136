import { Card, Col, Row, Table, Tag } from 'antd';
import { Text } from '../../../components';
import { useQuery } from '@apollo/client';
import {
  Query,
  Story,
  StoryStatus,
  GET_STORIES,
  QueryGetStoriesArgs,
} from '../../../graphql';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { startCase } from 'lodash';
import { useState } from 'react';

const Stories = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const { data, loading } = useQuery<Query, QueryGetStoriesArgs>(GET_STORIES, {
    variables: { page, take: pageSize },
  });

  const columns: ColumnsType<Story> = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'user.firstName',
      render: (item, record) =>
        record.user.firstName + ' ' + record.user.lastName,
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'user.email',
      render: (item, record) => record.user.email,
    },
    {
      key: 'createdAt',
      title: 'Registered At',
      dataIndex: 'createdAt',
      render: (item) => format(new Date(item), 'PP'),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (item) => (
        <Tag color={item === StoryStatus.Published ? 'blue' : 'orange'}>
          {startCase(item.toLowerCase())}
        </Tag>
      ),
    },
  ];

  return (
    <Card>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Text fontSize={20} fontWeight={500}>
            All stories
          </Text>
        </Col>
        <Col span={24}>
          <Table
            scroll={{ x: 'max-content' }}
            loading={loading}
            columns={columns}
            dataSource={data?.getStories?.data}
            rowClassName={'clickable'}
            onRow={(record) => ({
              onClick: () => navigate(`/stories/${record.id}`),
            })}
            pagination={{
              hideOnSinglePage: true,
              current: page,
              pageSize,
              total: data?.getStories?.count,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Stories;
