import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query getAllUsers(
    $page: Float
    $take: Float
    $roles: [UserRole!]
    $search: String
  ) {
    getUsers(page: $page, take: $take, roles: $roles, search: $search) {
      data {
        id
        firstName
        lastName
        email
        role
        status
        rejectionReason
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($userId: String!) {
    getUserById(userId: $userId) {
      id
      firstName
      lastName
      featured
      grantWinner
      email
      role
      status
      rejectionReason
      createdAt
      updatedAt
      profile {
        id
        bio
        pronouns
        location
        picture
        banner
        websiteUrl
        companyName
        jobTitle
        facebookUrl
        instagramUrl
        xUrl
        behanceUrl
        linkedInUrl
        tiktokUrl
        youtubeUrl
        storyteller {
          id
          background
          memorableInfluences
          professionalPathways
          currentInspirations
          ongoingProjects
          impactfulChanges
          influentialMedia
          legacyAspirations
          guidingAdvice
          images
          videoUrls
          template
        }
        guardian {
          id
          missionPassion
          guidingValues
          impactfulContributions
          conservationInitiatives
          inspirationalInfluences
          futureLegacy
          advocacyForChange
          mediaPerspectives
          storytellingRole
          storytellingFundraising
          guidanceForOthers
        }
        advocate {
          id
          activities
          localBeach
          inspirations
          coreValues
          environmentalChange
          contributions
          criticalAdvocacyChange
        }
        ambassador {
          id
          school
          oceanDiscovery
          educationalPursuits
          inspirationalFigures
          youthInitiatives
          environmentalChange
          favOceanReads
          futureLegacy
          guidance
        }
      }
    }
  }
`;

export const GET_STORIES = gql`
  query getStories($status: String, $page: Float, $take: Float) {
    getStories(status: $status, page: $page, take: $take) {
      data {
        id
        content
        image
        video
        status
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          role
        }
      }
      count
    }
  }
`;

export const GET_STORY_BY_ID = gql`
  query getStoryById($storyId: String!) {
    getStoryById(storyId: $storyId) {
      id
      content
      image
      video
      status
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        role
      }
    }
  }
`;

export const GET_FEATURED_ITEMS = gql`
  query getFeaturedItems {
    getFeaturedItems {
      hero {
        heading
        description
        buttonText
        buttonUrl
      }
      stories {
        id
        content
        image
        video
        status
        createdAt
        updatedAt
        featured
        user {
          id
          firstName
          lastName
          email
          role
        }
      }
      groups {
        id
        name
        description
        banner
        createdAt
        updatedAt
        featured
        membersCount
      }
      events {
        id
        title
        description
        banner
        createdAt
        updatedAt
        featured
        membersCount
        start
        end
      }
    }
  }
`;

export const GET_EVENTS = gql`
  query getEvents($page: Float, $take: Float, $type: String) {
    getEvents(page: $page, take: $take, type: $type) {
      data {
        id
        title
        description
        banner
        start
        end
        location {
          placeId
          title
          description
          lat
          long
          locationString
        }
        status
        createdAt
        updatedAt
        membersCount
        isMember
        owner {
          id
          firstName
          lastName
        }
      }
      count
    }
  }
`;

export const GET_EVENT_BY_ID = gql`
  query getEventById($eventId: String!) {
    getEventById(eventId: $eventId) {
      id
      title
      description
      banner
      status
      start
      end
      location {
        placeId
        title
        description
        lat
        long
        locationString
      }
      createdAt
      updatedAt
      owner {
        id
        firstName
        lastName
        profile {
          picture
        }
      }
      membersCount
    }
  }
`;

export const GET_GROUPS = gql`
  query getGroups($page: Float, $take: Float, $exclude: String) {
    getGroups(page: $page, take: $take, exclude: $exclude) {
      data {
        id
        name
        description
        banner
        status
        createdAt
        updatedAt
        membersCount
        isMember
        owner {
          id
          firstName
          lastName
        }
      }
      count
    }
  }
`;

export const GET_GROUP_BY_ID = gql`
  query getGroupById($groupId: String!) {
    getGroupById(groupId: $groupId) {
      id
      name
      description
      banner
      status
      createdAt
      updatedAt
      owner {
        id
        firstName
        lastName
        profile {
          picture
        }
      }
      membersCount
    }
  }
`;

export const GET_USERS_FILTER = gql`
  query getUsersFilter {
    getUsersFilter {
      role
      count
    }
  }
`;
